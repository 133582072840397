@import "reactdatepicker.scss";
@import "typography";
@import "hero";
@import "hamburgeranimation";
@import "flipcard";
@import "hidescrollbar";
@import "popularDestination";
@import "paywithcrypto";
@import "showfiatCurrency";
@import "forComponent";
@import "scrollOff";
@import "xcelSpecial";
@import "bookingSuccess";
@import "qrCode";
@import "promoSection";
@import "flightSearch";
@import "button";
@import "pagenotFound";
@import "payusing";
@import "currencyfiat";
@import "pagination";
@import "loadingAnimation";
@import "recentSearch";
@import "_contactUs";
@import "couponcard";
@import "MobileDownloadLink";
@import "sucessfail";
@import "_footer";
@import "_printPreview";
// @import "recentSearch";
@layer base {
  html {
    height: 100%;
    @apply scroll-smooth;
  }

  body {
    @apply overflow-x-hidden min-w-full font-satoshi;
    // background-image:url("../../src/assets/images/bg.jpg");
    // background-repeat: repeat-y;
    background-size: cover;
    background-color: rgba(242, 242, 242, 0.8);
    @apply dark:bg-dark-bg-primary;
    // letter-spacing: -0.025em;
    /* NEW CHANGES */
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  button {
    border-radius: 0.25rem !important;

    &:focus {
      outline: 0;
    }
  }

  //scrollbar edit
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 8px;
    height: 5px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  #wrapper {
    @apply flex flex-col relative;
    min-width: 375px;
  }

  main {
    @apply flex-1;
  }

  section {
    @apply py-8;

    @screen lg {
      @apply py-16;
    }
  }

  p {
    @apply mb-6 cursor-default;
  }

  td {
    @apply p-4;
  }

  .map {
    z-index: 0;
    width: 100%;
    height: 20rem;
    @apply rounded;
  }

  .phd-container {
    @apply container mx-auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-satoshi cursor-default;
    font-weight: 800;
  }

  h1 {
    @apply text-4xl mb-3 leading-tight;

    @screen md {
      @apply text-6xl leading-tight;
    }

    @screen lg {
      @apply leading-tight;
      font-size: 64px;
    }
  }

  h2 {
    @apply text-2xl font-bold relative mb-8 leading-tight;

    @screen lg {
      @apply text-5xl;
    }

    &.no-border {
      @apply pb-0;

      &::before {
        @apply hidden;
      }
    }
  }

  h3 {
    @apply text-xl font-semibold mb-3;

    @screen lg {
      @apply text-2xl;
    }
  }

  h4 {
    @apply text-xl font-semibold;
  }

  h5 {
    @apply text-lg font-semibold mb-3;
  }

  svg {
    @apply inline-block;
  }

  .right-angle {
    position: relative;
    @apply my-28;

    &::before,
    &::after {
      content: "";
      @apply absolute h-0 w-full;
      border-style: solid;
      border-width: 60px 0 0 100vw;

      @screen lg {
        border-width: 140px 0 0 100vw;
      }
    }

    &::before {
      @apply bottom-full;
      border-color: transparent transparent transparent rgb(17 24 39);
    }

    &::after {
      @apply top-full;
      border-color: rgb(17 24 39) transparent transparent transparent;
    }

    &-bottom {
      @apply mt-0;

      &::before {
        @apply hidden;
      }
    }

    &-top {
      @apply mb-0;

      &::after {
        @apply hidden;
      }
    }
  }
}

.container {
  padding: 0 1rem !important;

  @screen lg {
    max-width: 1340px !important;
  }
}

.left-2\/12 {
  @screen xl {
    left: 10%;
  }
}

.list {
  @apply pl-8;

  li {
    @apply pb-4 relative;

    &:before {
      position: absolute;
      left: -25px;
      content: "✓";
      @apply text-gray-900 font-semibold;
    }
  }
}
// .rotate-180 {
//   display: none;
// }
// svg.h-6.w-6.shrink-0 {
//   display: none;
// }
.dropdown-content {
  // top: 50px;
  // left: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);

  // left: -115px;
  // @screen lg {
  //     @apply  w-[24rem] max-w-xl;
  // }
}

.nav-toggle {
  svg {
    display: none;
  }
  &::before {
    content: url(/image/close-icon.svg);
    transform: scale(1.8);
    padding-top: 5px;
  }
}

.text-title {
  @apply text-primary-300 text-lg font-bold uppercase;
  color: black;
}

.popular-grid {
  grid-template-areas:
    "asia asia paris vietnam"
    "sydney honeymoon explore explore";
  .destination {
    border-radius: 4px;
  }

  .destination:nth-child(1) {
    @media screen and (min-width: 768px) {
      grid-area: asia;
    }
    backgroundcolor: #2c2547;
    a {
      color: white;
      .inner {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
  .destination:nth-child(2) {
    grid-area: paris;
  }
  .destination:nth-child(3) {
    grid-area: vietnam;
  }
  .destination:nth-child(4) {
    grid-area: sydney;
  }
  .destination:nth-child(5) {
    grid-area: honeymoon;
  }
  .destination:nth-child(6) {
    grid-area: explore;
  }
}

.result-grid {
  grid-template-columns: 0.5fr 1fr 1fr;
}

//guest dropdown

.wrapperadd {
  display: flex;
  align-items: center;
  border: 1px solid #e1e8ee;
}
.quantity {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 30px;
  font-size: 16px;
  font-weight: 300;
}

.btn {
  // border: 1px solid #e1e8ee;
  width: 30px;
  height: 30px;
  /*   border-radius: 6px; */
  cursor: pointer;
}
button:focus,
input:focus {
  outline: 0;
}

.btn--minus--children {
  @apply rounded-full flex items-center justify-center;
  border: 1px solid #e1e8ee;
  width: 24px;
  height: 24px;
}

.fullscreen_modal {
  // transition: all 0.5s;
  @apply rounded-t-2xl;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transform: translateY(130%);
}

.fullscreen_modal.show {
  left: 0;
  transform: translateY(0);
  // top: env(safe-area-inset-top);
  top: 30%;
  // opacity: 0;
}
.one {
  left: 0;
  transform: translateY(0);
  top: 0 !important;
}
.fullscreen_modal.modal-mobile {
  left: 0;
  transform: translateY(0);
  top: calc(100% - 600px);
}

.fullscreen_modal_scroll_area {
  // overflow-y: hidden;
  overflow-x: hidden;
  // margin-bottom: 80px;
  // overflow: hidden;
  // flex: 1 1;
  text-align: left;
  // overflow: hidden;
}

// .fullscreen_modal_scroll_off {
//   overflow: hidden;
// }
// .result-grid{
//   grid-template-columns: 0.5fr 1fr 1fr;
// }

.fullscreen_modal .date_picker {
  padding: 20px;
}

.fullscreen_modal {
  .react-datepicker-popper {
    position: relative;
    inset: auto;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .react-datepicker {
    display: flex;
    flex-direction: column;
    position: relative;
    right: 0;
  }
}

//button as component

.btn-1 {
  @apply rounded;
}

.button-2 {
  @apply rounded uppercase  text-base bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 text-white py-2 px-8;
}

.hotel {
  &-sidebar {
    .form-control {
      label {
        span {
          @apply text-sm mb-2.5 font-bold capitalize text-main-100;
        }
      }

      .search-icon {
        display: none;
      }
      input {
        @apply rounded-md  pl-2.5;
      }
    }
  }

  &-details4 {
    grid-template-columns: 60% 40%;
  }

  &-table {
    @media only screen and (max-width: 1024px) {
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        tr {
          border-bottom: 3px solid #ddd;
          display: block;
          margin-bottom: 0.625em;
        }
      }
      tbody {
        tr {
          // border-bottom: 3px solid #ddd;
          display: block;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(even) {
            border-bottom-width: 1px !important;
          }

          td {
            display: block;
            // font-size: 0.8em;
            // text-align: right;

            &:nth-child(even) {
              border-top: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
}

.modify {
  &-hotel {
    .form-control label {
      span {
        @apply text-button-100;
      }
    }
    span {
      @apply pb-1.5;
    }
    .group {
      @apply bg-white rounded-sm;

      .search-icon {
        @apply left-2;
      }

      input {
        @apply focus:pl-2;
      }
    }
  }

  &-search {
    .wrapper {
      @apply bg-transparent flex gap-2 p-0;

      &-inputLocation {
        @apply bg-[hsla(0,0%,100%,.1)] py-2 px-2 pl-[12px] flex-1  rounded-[4px] before:static;
        svg {
          path {
            fill: white;
          }
        }
        .form-control {
          label {
            @apply text-sm pb-1.5 flex;
          }
          .search-icon {
            @apply hidden;
          }
          input {
            @apply text-white text-sm md:text-base bg-transparent border-none ring-0 outline-none;
            padding-top: 0px !important;
          }
        }
        .showResult {
          @apply drop-shadow-[3px_10px_10px_rgba(0,0,0,0.15)];
          z-index: 2;

          svg {
            path {
              fill: black;
            }
          }
        }
      }

      &-inputDate {
        @apply p-0 flex-1 gap-2 flex-col;

        &-1 {
          @apply bg-[hsla(0,0%,100%,.1)] py-2 px-2 rounded-[4px] before:bg-transparent block;

          label {
            @apply pb-1.5 text-xs;
          }
          .undefined {
            .cursor-pointer {
              div {
                @apply block;
                .date {
                  @apply text-sm md:text-base;
                }
              }
            }
          }
        }
      }

      &-inputGuest {
        @apply bg-[hsla(0,0%,100%,.1)] py-2 px-2  rounded-[4px];
        label {
          @apply text-sm pb-1.5 flex;
        }

        label[tabindex="0"] {
          @apply inline;
        }

        .dropdown-content {
          .wrapperadd {
            button {
              @apply bg-white;
            }
          }
        }
        .dropdown {
          label {
            @apply text-sm md:text-base;
          }
        }
      }

      &-Search {
        @apply mx-0 my-0;
        button {
          @apply h-full mb-2 mt-0 lg:mt-auto;
          // background: #1a5fdb !important;
        }
      }

      &-FlightType {
        @apply bg-[hsla(0,0%,100%,.1)];
      }
    }

    #location {
      input {
        @apply p-0;
      }
      .search-icon {
        @apply hidden;
      }
      // svg {
      //   @apply hidden;
      // }
    }
    #guest {
      @apply pt-1.5;
    }

    // .group svg {
    //   @apply hidden;
    // }
    input {
      @apply p-0 text-lg pt-1.5;
    }

    .search-btn {
      button {
        @apply bg-blue-700 rounded h-full;
      }
    }

    label {
      @apply text-[#B589FF] p-0;
    }

    label.btn-ghost {
      @apply font-medium text-lg text-white;
    }

    .date_picker {
      @apply bg-transparent;
    }

    .dropdown-content {
      @apply text-black;

      button {
        @apply bg-[#f3f4f6];
      }
    }

    .fullscreen_modal_header {
      // @apply bg-slate-800 text-white;
    }
    .date {
      @apply text-white;
    }

    &-date {
      .cursor-pointer {
        div {
          @apply block;
          .text-3xl {
            @apply font-normal;
          }
          .date {
            @apply text-base;
          }
        }
      }
    }
  }
}

.FDSingle {
  .undefined {
    .cursor-pointer {
      div {
        span {
          @apply text-left;
        }
      }
    }
  }
}
.FDReturn {
  &-1 {
    .undefined {
      .cursor-pointer {
        div {
          span {
            @apply text-left;
          }
        }
      }
    }
  }
  &-2 {
    label {
      @apply justify-end lg:justify-start;
    }
    .undefined {
      .cursor-pointer {
        div {
          span {
            @apply text-right;
          }
        }
      }
    }
  }
}

.hotel {
  &-checkin,
  &-checkout {
    label {
      @apply text-button-100 text-sm;
    }

    input {
      @apply py-1.5 pl-1.5 rounded-sm;
    }
    .react-datepicker__input-container {
      input {
        @apply w-full;
      }
    }
  }
  &-coin {
    .w-fit {
      @apply bg-white rounded-sm p-1.5;
    }
  }
}

.customCalendar::before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: -8px;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

// .calendar-space:last-of-type {
//   @apply mt-8 md:mt-auto;
// }

//custom box-shadow ticket

.bs-ticket {
  &::after {
    box-shadow: inset 0.125rem 0 0.125rem hsla(0, 0%, 68%, 0.4);
  }
  &::before {
    box-shadow: inset -0.125rem 0 0.125rem hsla(0, 21%, 85%, 0.4);
  }
}

//loaderbg
.loader-bg {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
}

// custom table
.tr:nth-child(odd) {
  background-color: #f4f4f4;
}
//register

.register {
  &-input {
    .tooltip {
      top: 480px !important;
      left: 736px !important;
      @apply shadow-md;
    }
    .w-fit {
      width: 100%;
    }
    @apply mb-3;

    label {
      @apply mb-1 dark:text-dark-text-primary;
      span {
        @apply text-sm font-normal;
      }
    }

    input {
      @apply static  py-2  font-normal w-full   pl-2  focus:ring-newPrimary-100  focus:pl-2 rounded-[4px];
    }
    .search-icon {
      @apply hidden;
    }

    select {
      @apply w-full text-sm pl-2 rounded text-gray-500 font-normal border-[1px] border-[hsla(0,0%,60.8%,.5)];
    }
    .form-control {
      @apply pb-0;

      .relative {
        position: static;
      }

      p {
        @apply text-sm   mb-1;
      }
    }

    &-detailPage {
      .form-control {
        // p {
        //   @apply text-xs;
        // }
        label {
          span {
            @apply text-sm  capitalize;
            font-weight: 500 !important;
          }
        }
      }
    }
  }

  &-coupon {
    .relative.w-full {
      position: static;
    }
    input {
      @apply rounded-[4px] focus:ring-2 focus:ring-newPrimary-100 focus:border-transparent dark:bg-dark-bg-secondary #{!important};
    }
  }

  &-agree {
    @apply mb-2;
    .form-control {
    }
  }

  &-external {
    button {
      @apply w-full justify-center shadow-md rounded-sm;

      border: 1px solid #bababa !important;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
    }
  }

  &-divider {
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &::before,
    &::after {
      content: "";
      height: 1px;
      flex-grow: 1;

      @apply bg-gray-400;
    }

    &::before {
      margin-right: 1rem;
    }

    &::after {
      margin-left: 1rem;
    }
  }
}

//mobile phone number input css
.mobileNo-input-custom {
  // @apply grid md:grid-cols-2  md:gap-2 md:static md:border-0 border-[1px] md:h-auto h-auto;
  // grid-template-columns: 95px auto;
  @apply flex gap-2;

  .zipcode-only {
    @apply border-gray-400 dark:border-gray-600;
    div[data-testid="flowbite-dropdown"] {
      width: 200px !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      height: 25vh !important;
      left: -8px !important;
      top: -8px !important;
    }
  }
}
.zipcode-only {
  @apply mb-0 border dark:border-gray-600 flex rounded-md p-[8px] dark:text-dark-text-primary;

  div[data-testid="flowbite-tooltip-target"] {
    margin: auto;
    padding: 0 6px;
    margin-right: 6px;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 20px;
      background-color: lightgray;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  div[data-testid="flowbite-tooltip"] {
    min-width: 200px;
    height: 400px;
    overflow-y: scroll;
  }

  position: relative;
  .PhoneInput {
    @apply absolute left-2 translate-y-[-50%] top-[50%];
    .PhoneInputInput {
      @apply hidden;
    }

    .PhoneInputCountry {
      .PhoneInputCountryIcon--border {
        @apply shadow-none;
      }
    }
  }
  .custom-mobile-input {
    input {
      @apply h-full py-0 pl-0 text-sm border-0 bg-transparent w-[50px];
    }
  }

  .select__value-container {
    @apply hidden;
  }

  .css-13cymwt-control {
    border: none;
    display: grid;
    grid-template-columns: 20px 20px;
    padding-left: 4px;
  }

  .select__indicators {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select__indicator-separator {
    @apply hidden;
  }

  .css-1xc3v61-indicatorContainer {
    padding: 0px;
  }

  .css-1fdsijx-ValueContainer {
    @apply py-0;
  }

  .select__menu {
    width: 200px;
  }
}
.number-only {
  @apply h-[42px];
  position: relative;
  @apply text-sm;
  input {
    @apply focus:ring-newPrimary-100 focus:border-transparent  h-full  border shadow-sm rounded-md border-gray-400 dark:border-gray-600 dark:bg-dark-bg-secondary dark:text-dark-text-primary #{!important};

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
      --tw-ring-offset-width: 0px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: #1c64f2;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow);
    }
  }
  input:focus > .mobileNo-input-custom {
    background-color: red;
  }
}

///custom select input css
.select__menu {
  div {
    @apply rounded-md text-black dark:bg-dark-bg-primary dark:text-dark-text-primary;
  }
}

.css-tr4s17-option,
.css-d7l1ni-option {
  @apply bg-newPrimary-100/20 text-newPrimary-100 font-bold rounded-none cursor-pointer #{!important};
}

.custom-selectInput {
  div {
    border-radius: 4px !important;
    @apply text-sm dark:text-dark-text-primary;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
  }

  p {
    @apply pb-1.5 text-sm flex undefined;
    margin-bottom: 0.25rem !important;
    font-size: 14px !important;
  }
  .select-bordered {
    height: 41.6px !important;
    border: 1px solid hsla(0, 0%, 60.8%, 0.25);
    @apply focus:outline-0 border-gray-400 dark:border-gray-600;

    // height: 42px !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}
.select__control--is-focused,
.select__control--menu-is-open {
  background: transparent !important;
}

.css-13cymwt-control {
  border-radius: 0px !important;
  background: transparent !important;
}
.css-t3ipsp-control {
  box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border-color: transparent !important;
}

.select__input-container,
.css-qbdosj-Input {
  border: none !important;
  box-shadow: none !important;

  @apply focus:ring-0 focus:outline-0 focus:ring-offset-0 focus:shadow-sm;
  [type="text"]:focus {
    box-shadow: none !important;
  }
}

.select__option {
  &:hover {
    @apply bg-newPrimary-100/10 rounded-none #{!important};
  }
}

///navbar
.nav {
  &-fix {
    ul {
      @apply items-center gap-8 text-base;

      li,
      div {
        margin: 0 !important;
      }
    }
  }
}

.nav-padding {
  a {
    padding-left: 0px !important;
    @apply pr-[20px] md:pr-0;
  }
}

.crypto {
  &-drop {
    img {
      @apply h-8 w-8;
    }
  }
}

.fiat-tab {
  position: sticky;
  top: 0;
  background-color: white;
  flex-wrap: nowrap !important;
  gap: 0px;

  button {
    text-align: left;
    font-size: 12px;
    flex: 1;
    padding: 10px;
    border-radius: none !important;
  }

  button[aria-selected="true"] {
    border-radius: 0px !important;
  }

  button[aria-selected="true"] {
    @apply text-newText-100 border-newPrimary-100;
  }

  div[role="tabpanel"] {
    @apply py-0;
  }
}

.icn-holder span[data-tooltip] {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 6px;
}

[data-icon]:before {
  font-family: otaicons;
  /* BYO icon font, mapped smartly */
  content: attr(data-icon);
  speak: none;
  /* Not to be trusted, but hey. */
  // color: #767676;
}

.body-loader {
  backdrop-filter: blur(0px);
  position: fixed;
  inset: 0;
  z-index: 999;
  // background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page--loader {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

////// details accordion design
// summary::marker {
//   content: " ";
// }

// summary::after {
//   content: url(/image/chevron-circle-down-alt.svg);
//   display: none;
//   justify-content: center;
//   align-items: center;
//   padding-top: 6px;
//   width: 24px;
//   right: 0px;
//   height: 24px;
//   top: 20%;
//   transform: translateY(-50%);
//   position: absolute;
//   transition: all 0.3s ease-in;
// }

// details[open] > summary:after {
//   transform: rotate(180deg);
//   top: 0;
// }
.hotelRoomDetail {
  details > summary::-webkit-details-marker {
    display: none;
  }
  details[open] > summary:after {
    transform: rotate(3.142rad);
    right: 9px;
    top: -3px;
  }

  summary::marker {
    content: " ";
  }

  summary::after {
    content: url(/image/chevron-circle-down-alt.svg);
    @apply dark:invert;
    display: block;
    justify-content: center;
    align-items: center;
    width: 24px;
    right: 0;
    height: 24px;
    top: 15px;
    transform: translateY(-50%);
    position: absolute;
    // transition: all 0.3s ease-in;
  }
}

.moredetails-sum {
  .moredetails-summary::marker {
    content: " ";
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  details[open] > summary:after {
    transform: rotate(3.142rad);
    top: -5px;
    left: 78px;
  }

  .moredetails-summary::after {
    content: url(/image/chevron-circle-down-alt.svg);
    @apply dark:invert;
    display: block;
    justify-content: center;
    align-items: center;
    width: 24px;
    left: 85px;
    height: 24px;
    top: 15px;
    transform: translateY(-50%);
    position: absolute;
    // transition: all 0.3s ease-in;
  }
}

///priceslide edit
button.rheostat-handle {
  top: -6px !important;
  z-index: 1 !important;
  @apply bg-gradient-to-r from-newPrimary-100 to-newSecondary-100 w-4 h-4 shadow-none #{!important};
}
.rheostat-progress {
  @apply bg-newPrimary-100;
}
.rheostat-background,
.rheostat-progress {
  height: 2px !important;
}

///hotel image sliders css
///

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 2;
}

.reactmodal-modify {
  @apply rounded-xl shadow-m1 dark:bg-dark-bg-secondary dark:text-dark-text-primary;

  @media screen and (min-width: 640px) {
    max-width: 640px !important;
  }
  @media screen and (min-width: 768px) {
    max-width: 768px !important;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1000px !important;
  }
}
.reactmodal-modify-2 {
  @apply rounded-xl shadow-m1 dark:bg-dark-bg-secondary;
  @media screen and (min-width: 768px) {
    max-width: 500px;
  }
  transform: translate(50%, -50%);
}
.reactmodal-modify-3 {
  @media screen and (min-width: 768px) {
    max-width: 420px;
  }
  transform: translate(50%, -70%);
}

/// half-start ratings tripadvisor

.trip-halfstar {
  border-radius: 50px 0px 0px 50px;
  width: 5px;
  @apply translate-x-[20%];
}

.text {
  position: relative;
  // z-index: 90;
  flex: 1;
}

.location_text {
  font-size: 16px;
}

///xcelotel image
.clip-xcelotel {
  // clip-path: circle(23.6% at 50% 50%);
  // background-position: 20%;
  max-height: 24px;
  max-width: 24px;
  position: absolute;
  right: 6px;
  top: 6px;
}

.extracted-image {
  img {
    max-width: 100% !important;
  }
}

.hello {
  img {
    height: 100px !important;
  }
}
input:disabled {
  cursor: not-allowed;
}

.flight-dropdown {
  @apply rounded-full bg-gray-100 dark:bg-dark-bg-tertiary dark:text-dark-text-primary px-2 flex items-center;
  button {
    @apply text-xs uppercase;
  }

  svg {
    @apply ml-[2px];
  }
}

[data-testid="flowbite-tooltip"] {
  @apply shadow-m1 border-none;
}

.shareNearn {
  [data-testid="flowbite-tooltip-target"] {
    button {
      @apply dark:bg-transparent dark:hover:bg-transparent focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:ring-transparent hover:bg-transparent;
    }
    span {
      @apply py-1 px-3 bg-gray-100 dark:bg-dark-bg-tertiary dark:text-dark-text-primary text-vs rounded-full;
    }
  }
  div[data-testid="flowbite-tooltip"] {
    @apply dark:text-dark-text-primary dark:bg-dark-bg-primary;

    div[data-testid="flowbite-tooltip-arrow"] {
      @apply dark:bg-dark-bg-primary;
    }
  }
}

[data-testid="flowbite-tooltip-arrow"] {
  @apply shadow  drop-shadow-[3px_-10px_10px_rgba(0,0,0,0.15)];
}

.hotelDetails-topImg {
  @apply lg:grid-cols-3 grid-cols-2;
  img {
    height: 260px !important;
    // max-height: 250px;
  }
}

.changePassword {
  .form-control {
    @apply mb-3;

    label {
      @apply mb-2;
      span {
        @apply capitalize;
      }
    }
  }

  .field {
    @apply text-sm mb-3 text-gray-500;
    label {
      @apply flex items-center gap-1;
    }
  }

  &-progressbar {
    @apply flex items-center gap-2 mt-1;

    [aria-label="progressbar"] {
      @apply flex-1;
    }
  }
}

.dropzoneWrapper {
  @apply flex flex-col gap-4 mb-4;
  .dropzone {
    @apply w-[fit-content] order-2 bg-newPrimary-100/5 p-2  rounded-md text-sm cursor-pointer;
  }
}

.mobile__app {
  background: white;
  @apply dark:bg-dark-bg-primary;
  padding: 10px 5px;
}

@media screen and (min-width: 767px) {
  .mobile__app {
    display: none;
  }
}

.input-error {
  animation-name: shakeError;
  animation-fill-mode: forward;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

// Key frame for the shake error
// You can tweak the transforms to get any effect you want.
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

.airline-show:hover {
  a {
    display: inline-block;
  }
}

button[aria-selected="true"],
button[aria-selected="false"] {
  border-radius: 0px !important;
}

div[data-testid="table-element"] {
  @apply rounded shadow-none border-[1px];
  position: static !important;
}

.arrowShaped {
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  @apply w-32 h-32 lg:w-56 lg:h-48 ml-[-20px] lg:ml-[-40px] first:ml-0;

  &-1 {
    background: linear-gradient(#255c99, #3e8bcc);
  }
  &-2 {
    background: linear-gradient(#595174, #7c71a3);
  }
  &-3 {
    background: linear-gradient(#b06aad, #e77ca1);
  }
  &-4 {
    background: linear-gradient(#bc444f, #d7783f);
  }
}

// <----------------------hotel list mobile responsive -------->
.hotel-list_mobile {
  @apply h-[190px] md:h-[173px];

  &_star {
    svg {
      @apply w-4 h-4 sm:w-5 sm:h-5;
    }
  }
}

// <-----------navslide----->
.show-Nav {
  details {
    a {
      display: block;
      @apply pl-6 pb-2   pt-4 first:pt-0 border-b dark:border-dark-bg-tertiary;
    }
    summary {
      display: flex;
      align-items: center;
      list-style: none;
      gap: 4px;
      @apply p-2 rounded-md;

      &::after {
        content: url(/image/dropdown.svg);
        @apply dark:invert;
        // filter: invert(100);
        // display: inline-block;
        position: static;
        transform: translateY(0);
        padding-top: 3px;
      }
    }
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  details[open] > summary:after {
    transform: rotate(180deg);
  }

  details[open] > summary {
    @apply bg-newPrimary-100/10;
  }

  .p_2 {
    li {
      @apply mb-0 pl-2;

      &:first-child {
        button {
          @apply text-black;
        }
      }

      &:last-child {
        button {
          @apply bg-transparent rounded-md  bg-newPrimary-100 text-white w-full;
        }
      }
    }
  }
}

// <---------------amenityedit on hotel list page--------->
.amenity-edit {
  div::before {
    @apply text-green-500 dark:text-green-400;
  }
}

// <---- select room on hotel details btn------>

.select-room {
  @media screen and (min-width: 480px) and (max-width: 640px) {
    @apply flex;
  }

  &_btn {
    @media screen and (min-width: 375px) and (max-width: 640px) {
      @apply px-2;
    }
  }
}

// <------------herosection------------>

#hero {
  @apply pt-0;
  // @media screen and (min-width: 1024px) {
  //   background-image: url(/image/homepage/hero-image.webp);
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: 40%;
  // }
  // @media screen and (min-width: 768px) {
  //   background-image: url(/image/homepage/hero-image.webp);
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: 40%;
  // }
  // @media screen and (min-width: 640px) {
  //   background-image: url(/image/homepage/hero-image.webp);
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: 40%;
  // }
  @media screen and (min-width: 320px) {
    // background-size: cover;
    // background-repeat: no-repeat;
    // border-radius: 50px;
    // background-position: 40%;
  }

  // img {
  //   filter: brightness(0.8);
  // }

  .bg-holder {
    background-size: cover;
    background-repeat: no-repeat;
    // @apply md:rounded-b-[24px] rounded-b-[16px];
    background-position: 40%;
  }
}

// <--------------------------press media coverage*------------------->

div [id=":Rcqm:"] {
  a {
    li {
      text-transform: uppercase !important;
      font-size: 12px !important;

      &::marker {
        content: " ";
      }
    }
  }
}

// <------------------comingsoonPageimage------->

#comingsoon_image {
  background-image: url(/image/ComingSoon/Coming.png);
  // background-image: url(/image/homepage/hero-image.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40%;
}

.FAQ_Tab {
  div[role="tabpanel"] {
    @apply bg-newText-100/5 p-6 px-12 rounded-lg mt-4;
  }

  button[aria-selected="true"] {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    @apply bg-newPrimary-100 text-white;
    @apply ring-0;
  }

  summary {
    @apply text-lg;

    p {
      @apply text-base;
    }
  }

  .reset_FAQ {
    details {
      summary {
        &:after,
        &:before {
          content: unset;
        }
        &::marker {
          content: unset;
        }
      }
    }

    .heading_details {
      details[open] > summary:after {
        position: absolute;
        transform: rotate(0deg);
        left: 0px;
        padding-bottom: 1rem;
      }
      summary {
        @apply relative pl-6;
        &::after {
          @apply absolute left-0 pt-[3px];
          content: url("/image/FAQ_icon/chevron-right-primary.svg");
        }
      }
      summary::marker {
        content: "";
      }
    }
  }
}

// <-----------------made custom shadows---------->

.shadow-m1 {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.xxxx {
  clip-path: polygon(
    74.1% 44.1%,
    100% 61.6%,
    97.5% 26.9%,
    85.5% 0.1%,
    80.7% 2%,
    72.5% 32.5%,
    60.2% 62.4%,
    52.4% 68.1%,
    47.5% 58.3%,
    45.2% 34.5%,
    27.5% 76.7%,
    0.1% 64.9%,
    17.9% 100%,
    27.6% 76.8%,
    76.1% 97.7%,
    74.1% 44.1%
  );
}

.xxxxx {
  clip-path: polygon(
    74.1% 44.1%,
    100% 61.6%,
    97.5% 26.9%,
    85.5% 0.1%,
    80.7% 2%,
    72.5% 32.5%,
    60.2% 62.4%,
    52.4% 68.1%,
    47.5% 58.3%,
    45.2% 34.5%,
    27.5% 76.7%,
    0.1% 64.9%,
    7 17.9% 100%,
    27.6% 76.8%,
    76.1% 97.7%,
    74.1% 44.1%
  );
}

.lyt-img {
  filter: opacity(0.3);
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

div[data-testid="flowbite-accordion"] {
  svg.h-6.w-6.shrink-0.rotate-180 {
    display: none;
  }

  button {
    svg[data-testid="flowbite-accordion-arrow"] {
      display: none;
    }
  }
}

.select-room_btn {
  @apply w-full;
}

.mybookings_tabs {
  div[aria-label="Tabs with icons"] {
    @apply justify-center lg:justify-start mb-4;
  }
  div[aria-labelledby=":R1qm:-tab-0"],
  div[aria-labelledby=":R1qm:-tab-1"] {
    @apply lg:pl-0 p-0;
  }
  button[aria-selected="true"] {
    @apply text-newPrimary-100 border-newPrimary-100;
  }
  button {
    @apply focus:ring-0;
  }
}

.lyt-faq {
  div[data-testid="flowbite-accordion"] {
    svg.h-6.w-6.shrink-0.rotate-180 {
      display: unset;
    }

    button {
      svg[data-testid="flowbite-accordion-arrow"] {
        display: unset;
      }
      h2 {
        @apply text-base m-0;
      }
    }
  }
}

@keyframes animateText {
  0% {
    background-position: -100%;
  }

  to {
    background-position: 100%;
  }
}

.animate {
  background-image: linear-gradient(
    45deg,
    #7553fc,
    #5b7af5,
    #7553fc,
    #346be3,
    #811ff9,
    #5b7af5,
    #7553fc,
    #7553fc,
    #5b7af5,
    #7553fc,
    #346be3,
    #811ff9,
    #5b7af5,
    #7553fc
  );
  -webkit-background-clip: text;
  position: relative;
  background-clip: text;
  color: transparent;
  background-size: 500%;
  background-position: -100%;
  animation: animateText 9s linear infinite;
}

// overriding the radiobutton input check

input[type="checkbox"] {
  @apply text-newPrimary-100 ring-0 shadow-none ring-transparent dark:bg-dark-bg-tertiary;

  &:focus {
    @apply outline-0 ring-0 shadow-none ring-transparent;
  }
}

// Map Height css

.Map_map__9eK65 {
  height: 460px !important;
}

button[aria-expanded="false"],
button[aria-haspopup="menu"],
button[id="#:R29qamH1:"] {
  @apply ml-0 #{!important};
}

.image-transition {
  transition: transform 0.3s ease-in-out;
}

/////toggle switch

.toggle-bg:after {
  @apply bg-primary-500;
}

.hoteldetails_removeBR {
  br {
    @apply hidden;
  }
}

.advertisement_image {
  img {
    @apply rounded-lg;
  }
}

.swiper-pagination {
  bottom: 0px !important;
}

.arrow_price {
  svg path {
    @apply dark:invert;
  }
}

.blog-details {
  h2,
  p,
  li {
    @apply dark:text-dark-text-primary;
  }
}
