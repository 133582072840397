@media print {
  .scrollableContent {
    overflow: visible !important;
  }

  .not-printable {
    display: none !important;
  }
}

.print {
  padding: 20px 0px;
}

.print .bg-white {
  /* padding: 10px 20px; */
  border: none;
  padding: 0;
}

.print .rounded {
  padding: 10px 20px;
}

.print .head img {
  display: none;
}

@media print {
  @page {
    size: 300mm 427mm;
  }

  .container {
    padding: 0 !important;
    margin: 0 !important;
    max-width: none !important;
  }

  .print_photos {
    display: flex !important;
  }

  .print_main_bg {
    box-shadow: none !important;
    border: none !important;
  }

  div[aria-label="Tabs with icons"] {
    display: none;
  }
}
