.spinner-scroll-off {
  overflow: hidden !important;
  position: fixed;

  &:before {
    position: fixed;
    content: " ";
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 3;
  }
}

.iframe-scroll-off {
  overflow: hidden !important;

}

// .scroll-off {
//   overflow: hidden;
//   // pointer-events: none;
// }

// .body-scroll-off {
//   body {
//     overflow: hidden;
//   }
// }