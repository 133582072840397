.flightInfo {
  &-duration🙌stop {
    span {
      @apply hidden;
    }

    h3 {
      @apply text-sm mb-1 ml-2;
    }
  }
  &-image {
    img {
      @apply w-6 h-6   my-auto inline-block;
    }
    p {
      @apply text-xs ml-2 my-auto inline-block;
    }
  }
  &-duration {
    h3 {
      @apply text-sm text-center font-normal mb-1;
    }
    span.sub-text {
      @apply hidden;
    }
  }

  &-stops {
    .mt-4 {
      @apply mt-2;

      div {
        @apply w-full;
      }
    }
    .mobileFlightStops {
      @apply w-full;
    }
  }

  &-selection {
    p {
      @apply mt-1;
    }

    button {
      // @apply px-4 py-1 text-sm;
    }
  }
}

///national flight css

.NationalFlight {
  &-Duration {
    h3 {
      @apply mb-[-4px];
    }
    span {
      @apply text-[10px] mb-[-5px];
    }
  }
}

///selecting flight csss

.SelectingFlight {
  &-MobileBtn {
    button {
      @apply text-white drop-shadow-none bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700;
    }
  }
}
