.card_coupon {
  //   width: 400px;
  height: fit-content;
  border-radius: 4px;
  // box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.054);
  background-color: #fff;
  @apply dark:bg-dark-bg-tertiary dark:text-dark-text-primary;
  padding: 10px 10px 10px 18px;
  position: relative;
}

.main_coupon,
.copy-button {
  display: flex;
  padding: 0 0px;
  align-items: center;
}
// .card_coupon::after {
//   position: absolute;
//   content: "";
//   height: 20px;
//   right: -14px;
//   border-radius: 40px;
//   //   z-index: 1;
//   top: 25px;
//   background-color: #fff;
//   width: 20px;
//   box-shadow: inset 4px 0 5px -2px rgba(0, 0, 0, 0.2);
// }

.card_coupon::before {
  position: absolute;
  content: "";
  height: 20px;
  left: -11px;
  border-radius: 40px;
  //   z-index: 1;
  top: 35%;
  background-color: #fff;
  width: 20px;
  box-shadow: inset -4px 3px 3px -4px rgba(2, 1, 1, 0.2);
  display: none;
}

.co-img img {
  width: 100px;
  height: 100px;
}

.content_coupon h1 {
  font-size: 35px;
  margin-left: -20px;
  color: #565656;
}

.content_coupon h1 span {
  font-size: 18px;
}
.content_coupon h2 {
  font-size: 18px;
  margin-left: -20px;
  color: #565656;
  text-transform: uppercase;
}

.content_coupon p {
  font-size: 16px;
  color: #696969;
  margin-left: -20px;
}

.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.copy-button button {
  padding: 5px 20px;
  background-color: #dc143c;
  color: #fff;
  border: 1px solid transparent;
}
