.react-datepicker__input-container {
  input {
    @apply w-full  rounded-md undefined border-gray-200 bg-white text-sm text-gray-700 shadow-sm
    undefined;
  }
}

.react-datepicker__header {
  background-color: white !important;
}

.react-datepicker {
  border-radius: 12px !important;
  overflow: hidden;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 12px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 3px !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  border-radius: 100%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
  @apply border border-blue-600;
  color: rgb(28 100 242 / var(--tw-border-opacity)) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500;
  color: white !important;
}

.react-datepicker__navigation--years-upcoming {
  text-indent: 0em !important;
  &::after {
    position: absolute;
    content: "\23F6";
    left: 8px;
    font-size: 16px;
    top: 6px;
  }
}

.react-datepicker__navigation--years-previous {
  text-indent: 0em !important;
  &::after {
    position: absolute;
    content: "\23F7";
    left: 8px;
    font-size: 16px;
    top: 6px;
  }
}
