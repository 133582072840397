// .MobileLink {
//   background-image: linear-gradient(
//       to bottom,
//       rgba(0, 0, 0, 0),
//       rgba(0, 0, 0, 0.5)
//     ),
//     url(/image/MobileLinkDownload/image-link.webp);
//   background-repeat: no-repeat;
// }

.mobile-select {
  select:not([size]) {
    padding-right: 4px ;
  }
}
