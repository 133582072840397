@tailwind base;
@tailwind utilities;
@tailwind components;
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';
@import "base/index.scss";

@import "range.css";
