.showfiat-mobile {
  a {
    @apply p-0;
  }
  li {
    list-style-type: none;
  }
  img {
    width: 16px !important;
    height: 16px !important;
  }
}
