.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pagination {
    margin: 15px 20px;
    display: flex;
    list-style: none;
    outline: none;
  }

  .custom-selectInput {
    padding-bottom: 0px !important;
  }
  .pagination > .active > a {
    background-color: #2680eb;
    border-color: #2680eb;
    color: #fff;
  }
  .pagination > li > a {
    border: 1px solid #d1d1d1;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #2680eb;
    border-color: #2680eb;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #2680eb;
  }

  .previous {
    a {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.disabled {
      opacity: 0.5;
      a {
        background: #efefef;
        cursor: not-allowed;
      }
    }
  }
  .next {
    a {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.disabled {
      opacity: 0.5;
      a {
        background: #efefef;
        cursor: not-allowed;
      }
    }
  }
}

.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  @apply dark:bg-primary-200;
}
