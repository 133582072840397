.button {
  &-hotel {
  }
  &-flight {
    @apply rounded-[4px] text-base py-2 px-4 bg-gradient-to-tr  from-newSecondary-100 to-newPrimary-100  text-white #{!important};
    span {
      padding: 0 !important;
    }
  }
  &_confirm {
  }
}

.bg-gradient-main {
  @apply bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500;
}

.border-gradient {
  @apply border ;
}

button:disabled {
  @apply cursor-not-allowed opacity-[0.7];
}
