.bg-img:after {
  content: "";
  position: absolute;
  left: -50px;
  bottom: 0;
  width: 150px;
  height: 330px;
  background: url(../../../public/image/imp-avatar.png) no-repeat left bottom;
  background-size: 131px 199px;
}

.img-holder-md {
  width: 400px;
  height: 300px;
}

.img-holder-md img {
  object-fit: contain;
}


.app-download {
  background-color: #f5fcff;
  border: 1px solid #c9e0ea;
}

.imp .apply .content {
  position: relative;
  z-index: 10;
}

.bg-property:after {
  content: "";
  position: absolute;
  right: -50px;
  bottom: 0;
  width: 150px;
  height: 330px;
  background: url(../../../public/image/bookingSuccess/burj.png) no-repeat right 150%;
  background-size: 130px 287px;
}