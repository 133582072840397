// // .crd {
// //     text-align: left;
// //     margin: 0 24px;

// //     .px-2 {
// //         background: white;

// //         h5 {
// //             margin: 0;
// //         }
// //     }
// // }

// .special {
//   padding: 40px 0;

//   .flex {
//     gap: 16px;
//   }

//   h3 {
//     @apply font-semibold text-xl sm:text-3xl mb-4;
//   }
// }

// .special .crd .card-holder {
//   padding: 20px;
// }

// .special .crd {
//   @apply rounded;
//   border: 1px solid #e5e5e5;
//   box-shadow: 0 0 10px #e5e5e5;
//   // max-height: 500px;
//   padding: 20px;
//   overflow: hidden;
// }

// .special .crd h5,
// h4 {
//   color: #003c75;
//   font-size: 16px;
//   font-weight: 600;
//   margin: 16px 0 !important;
// }

// .special .crd .img-holder {
//   // height: auto;
//   /* width: 320px; */
//   overflow: hidden;
//   @apply rounded-t;
// }

// .special .crd .card-holder label {
//   color: #003c75;
//   font-size: 12px;
//   /* width: 50%; */
// }

// .special .crd .card-holder date {
//   color: #c5c5c5;
//   font-size: 14px;
//   letter-spacing: 0;
// }

// @media screen and (max-width: 1199.98px) {
//   .special .crd {
//     margin: 0 auto 10px auto;
//   }

//   .special h3 {
//     text-align: center;
//   }
// }

// @media screen and (max-width: 767px) {
//   .special .crd {
//     margin: 10px auto;
//   }
// }

// @media (max-width: 768px) {
//   .special .flex::-webkit-scrollbar {
//     display: none !important;
//   }

//   .special .flex {
//     display: flex !important;
//     flex-direction: row !important;
//     overflow-x: scroll !important;
//   }

//   .special .flex .crd {
//     min-width: 240px;
//   }

//   .founders-msg {
//     flex-direction: column !important;
//   }

//   .founders-msg > * {
//     flex: 1 !important;
//   }

//   .video {
//     width: 100%;
//   }

//   .img_founder {
//     flex: 1;
//     width: 100%;
//   }

//   .cryptos {
//     flex-wrap: nowrap !important;
//     overflow-x: scroll !important;
//     box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.3);
//     padding: 5px 10px;
//     gap: 2em !important;
//   }

//   .cryptos::-webkit-scrollbar {
//     display: none !important;
//   }

//   .crypto {
//     flex: 1 !important;
//     gap: 0 !important;
//     display: flex !important;
//     flex-direction: column !important;
//   }

//   .crypto_img {
//     max-width: none !important;
//   }
// }

.container .special {
  .gap-6 {
    @apply md:flex-row flex-col;
  }
  h3 {
    @apply capitalize font-bold text-xl sm:text-3xl text-black m-0 mb-4;
  }
  .crd {
    @apply bg-white rounded-md p-4 flex-1;
  }

  .img-holder {
    @apply flex items-center justify-center md:h-[400px] overflow-hidden;

    &_2 {
      @apply scale-[0.95];
    }
    &_1,
    &_3 {
      @apply scale-[0.7];
    }
  }

  p {
    @apply my-4;
  }

  .download_app {
    @apply flex items-center gap-2 justify-center;
  }
}
