.qr-code {
  transform: scale(5);
}

.qr__block:hover .qrcode__box,
.qr__block:hover .qrcode__box-down {
  opacity: 1;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 200px;
  z-index: 1000;
  transform: scale(1);
  @apply dark:bg-dark-bg-primary dark:border-transparent;
}
.qrcode__box-header {
  padding: 10px;
  margin: 5px;
  //   right: -70px;
  width: auto;
  height: auto;
}
.qrcode__box {
  position: absolute;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  transform: scale(0);
  padding: 4px;
  opacity: 0;
  z-index: -1000;
  transition: all 0.2s ease-in-out;
  max-height: 0;
  width: 160px;
  height: fit-content;
  right: 0;
  top: 40px;

  @media screen and (min-width: 1440px) {
    left: auto;
  }
  @media screen and (min-width: 1024px) {
    left: -100px;
  }
}

// .list-token {
// }
// .list-token:hover .list-token-content {
//   transform: scale(1);
//   opacity: 1;
//   color: black;
// }
// .list-token-content {
//   transition: all 0.2s ease-in-out;
//   opacity: 0;
//   transform: scale(0);
// }

// <-------------------------this is for the list your tyoken---------------->

.LC-dropdown {
  svg.ml-2.h-4.w-4 {
    @apply hidden;
  }

  button {
    background: none !important;
    color: black;
    @apply focus:ring-0;

    span {
      @apply p-0;
    }
  }
}
