@media screen and (min-width: 768px) {
  .slick-slide {
    // width: 200px !important;
    // margin-right: 16px;
    // .recentSearchSlide {
    //   @apply p-0 m-0;
    // }

    // @apply lg:mr-0 mr-3 last:mr-0;
  }
  //   .slick-slider.slick-initialized,
  //   .slick-track {
  //     @apply w-fit #{!important};
  //   }
}
@media screen and (min-width: 1024px) {
  .slick-track {
    display: flex !important;
    gap: 12px;
    width: auto !important;
    justify-content: start;
    margin-left: -12px !important;
  }
}
.slick-prev,
.slick-next {
  top: -30px !important;
}

.slick-prev {
  left: calc(100% - 90px) !important;
}

.slick-next {
  left: calc(100% - 50px) !important;
}

#recentSearch {
  .slick-prev:before,
  .slick-next:before {
    @apply text-newPrimary-100 #{!important};
    font-size: 24px;
  }
}

.slick-prev {
  // z-index: 1 !important;
  @apply hidden md:block  #{!important};
}
.slick-next {
  @apply hidden md:block  #{!important};
}

// @apply [@media(min-width:480px)]:max-w-[calc(100%-16px)] [@media(min-width:1024px)]:max-w-none #{!important};

// @media screen and (min-width: 480px) {
//   .recentSearchSlide {
//     max-width: calc(100% - 16px);
//   }
// }
@media screen and (max-width: 1024px) {
  .recentSearchSlide {
    max-width: calc(100% - 16px);
  }
}

.slick-slide {
  @apply px-2 last:pr-0;
}

.slick-track {
  @apply mb-2;
}
