.SVG_animate {
  overflow: visible !important;
  width: 150px;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  svg {
    overflow: visible !important;
  }
}

#left-hearts {
}

#left-hearts > *,
#right-hearts > * {
  transform-origin: center center;
  animation: floatingHearts 0.65s cubic-bezier(0.12, 0.84, 0.5, 0.44) forwards;
}
#right-hearts {
  g:first-child {
    animation-delay: 0.3s;
  }
  g:last-child {
    animation-delay: 0.1s;
  }
}
#left-hearts {
  g:first-child {
    animation-delay: 0.2s;
  }
  g:last-child {
    animation-delay: 0.5s;
  }
}

@keyframes floatingHearts {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.fade {
  animation: new 0.8s ease-in-out;
}

@keyframes new {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sucess_detail {
  .print_main_bg {
    @apply shadow-none p-0;
  }
}
