.tab {
  @apply transition duration-700 ease-in-out;
  &-boxed {
  }

  &-active {
    backgroundcolor: #f2f7ff;
    border-radius: 4px;
    // padding: 0 16px;
  }
}

#hero {
  // background: linear-gradient(
  //   180deg,
  //   rgb(195 203 224) 0%,
  //   rgb(194 203 224) 100%
  // );
  // height: 100vh;
  // background:url(/image/hero-image.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: 50% 50%;

  .form-control {
    label.label {
      padding-top: 0;
    }
  }
  .date_picker.custom_calendar {
    width: 100%;
    background: transparent;
  }
  .react-datepicker-popper {
    @media not all and (max-width: 1024px) {
      position: absolute;
      inset: 0px auto auto -170px !important;
      transform: translate(0px, 65px) !important;
    }
    .react-datepicker {
      @media not all and (min-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
      border: none;
      display: flex;
      gap: 15px;
      padding: 1em;
      &__navigation--next {
        right: 14px;
        top: 21px;
      }
      &__navigation--previous {
        left: 14px;
        top: 21px;
      }
      &__triangle {
        &::after,
        &::before {
          display: none;
        }
      }
      &__month-container {
        float: none;
      }
      &__header {
        background: white;
        border: none;
      }
      .react-datepicker__current-month {
        padding-bottom: 10px;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        border-radius: 0.1rem;
        backgroundcolor: #ffd12e;
        color: #000;
      }
    }
  }
}

.activeIndicatorBG {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: auto;
  display: flex;
  height: 44px;
  margin: 6px;
  -webkit-box-align: center;
  align-items: center;
  // border-radius: 8px;
  // background: linear-gradient(
  //   90deg,
  //   rgb(249, 251, 111) 0%,
  //   rgb(238, 235, 92) 33%,
  //   rgb(245, 230, 97) 100%
  // );
  // outline: 1px solid red;
  // box-shadow: rgb(255 255 255) 1px 1px 0px 2px;

  cursor: pointer;
  // width: 108px;
  transform: translateX(0px);
  transition: all 100ms cubic-bezier(0, 0, 0.58, 1) 0s;

  &::after {
    content: "";
    @apply bg-gradient-to-l  from-newSecondary-100 to-newPrimary-100;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 4px;
    width: 80px;
  }
}

.room-accordion {
  overflow-y: auto;
  h2 {
    @apply text-sm m-0;
  }
  button[type="button"] {
    @apply bg-gray-50  mb-2 pb-2;
  }
}

.search_placeholder {
  ::placeholder {
    @apply font-normal;
  }
  input {
    @apply font-medium text-black text-base shadow-none;
  }
}

.hero_tabs {
  div[aria-label="Tabs with underline"] {
    @apply mx-auto sm:w-[600px] justify-center;
  }
  button {
    @apply text-xl;
  }
}
