.currency-tab {
  button[aria-selected="true"] {
    @apply text-newText-100 border-newText-100;
  }
  button {
    &:focus {
      @apply outline-none  ring-offset-0 ring-0 #{!important};
    }
  }
}
