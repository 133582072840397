$base-duration: 500ms;

// Colors
$color-1: #2c3e50;
$color-2: #3498db;
$color-3: #2ecc71;
$black: #333;

// Breakpoints
// $sm: new-breakpoint(min-width 320px);
// $med: new-breakpoint(min-width 768px);
// $lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.hamburger {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  //padding: 5% 0 5% 5%;
  // z-index: 51;
  z-index: 2;
  transform: rotateY(-180deg);

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 2px;
    background-color: white;
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 2px;
      background-color: white;
    }

    &:before {
      transform: translateY(-5px);
      width: 18px;
      animation: burger3 250ms linear;
    }
    &:after {
      width: 16px;
    }

    @keyframes burger3 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-5px) rotate(0deg);
      }
    }

    &:after {
      transform: translateY(5px);
      animation: burger4 250ms linear;
    }

    @keyframes burger4 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(5px) rotate(0deg);
      }
    }
  }
}

.hamburger-white {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  //padding: 5% 0 5% 5%;
  // z-index: 51;
  z-index: 2;
  transform: rotateY(-180deg);

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 2px;
    background-color: black;
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 2px;
      background-color: black;
    }

    &:before {
      transform: translateY(-5px);
      width: 18px;
      animation: burger3 250ms linear;
    }
    &:after {
      width: 16px;
    }

    @keyframes burger3 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-5px) rotate(0deg);
      }
    }

    &:after {
      transform: translateY(5px);
      animation: burger4 250ms linear;
    }

    @keyframes burger4 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(5px) rotate(0deg);
      }
    }
  }
}

.menu {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  margin: 0;
  padding: 100px 15px 0;
  background-color: red;
  transition: height 150ms linear;
  z-index: 998;

  li {
    a {
      color: $black;
      font-weight: 400;

      i {
      }
    }

    .dropdown {
      display: none;
    }

    &:hover {
      .dropdown {
        &:before {
        }

        li {
          a {
            &:hover,
            &:active,
            &:focus {
            }
          }
        }
      }
    }
  }
}

// #menu {
//   display: none;

//   &:checked ~ .hamburger {
//     > div {
//       visibility: hidden;

//       &:before,
//       &:after {
//         visibility: visible;
//         background-color: black;
//         transform-origin: center center;
//         transform: translateY(0);
//         transition: all 250ms linear;
//       }

//       &:before {
//         transform: rotate(-45deg);
//         animation: burger1 250ms linear;
//         width: 20px;
//       }

//       @keyframes burger1 {
//         0% {
//           transform: translateY(0);
//         }
//         50% {
//           transform: translateY(0);
//         }
//         100% {
//           transform: translateY(0) rotate(-45deg);
//         }
//       }

//       &:after {
//         transform: rotate(45deg);
//         animation: burger2 250ms linear;
//         width: 20px;
//       }

//       @keyframes burger2 {
//         0% {
//           transform: translateY(0);
//         }
//         50% {
//           transform: translateY(0);
//         }
//         100% {
//           transform: translateY(0) rotate(45deg);
//         }
//       }
//     }
//   }

//   &:checked ~ .menu {
//     display: block;
//     height: 100%;
//     transition: height 150ms linear;
//   }
// }

#menu {
  display: none;

  &:checked ~ .hamburger-white {
    display: none;
    visibility: hidden;
    > div {
      visibility: hidden;

      &:before,
      &:after {
        visibility: visible;
        background-color: red;
        transform-origin: center center;
        transform: translateY(0);
        transition: all 250ms linear;
      }

      // &:before {
      //   transform: rotate(-45deg);
      //   animation: burger1 250ms linear;
      //   width: 20px;
      // }

      // @keyframes burger1 {
      //   0% {
      //     transform: translateY(0);
      //   }
      //   50% {
      //     transform: translateY(0);
      //   }
      //   100% {
      //     transform: translateY(0) rotate(-45deg);
      //   }
      // }

      // &:after {
      //   transform: rotate(45deg);
      //   animation: burger2 250ms linear;
      //   width: 20px;
      // }

      // @keyframes burger2 {
      //   0% {
      //     transform: translateY(0);
      //   }
      //   50% {
      //     transform: translateY(0);
      //   }
      //   100% {
      //     transform: translateY(0) rotate(45deg);
      //   }
      // }
    }
  }

  &:checked ~ .menu {
    display: block;
    height: 100%;
    transition: height 150ms linear;
  }
}

.transition-all {
  transition: all 0.5s;
}

.menu-cross {
  #cross_btn {
    display: none;
  }
  span {
    background-color: red;
    width: 4px;
    height: 4px;
  }
}
