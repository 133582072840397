.rheostat {
    overflow: visible;
  }
  
  
  .rheostat-progress {
    background-color: #41A7F5;
  }
  
  .rheostat-background {
    background-color: #e5e5e5;
  }
  
  .rheostat-handle {
    background-color: #fff;
    box-shadow: 2px 3px 5px #C5CCD6;
    border-radius: 50%;
    height: 24px;
    outline: none;
    z-index: 2;
    border: 1px solid #C5CCD6;
    width: 24px;
  }
  
  .rheostat-horizontal {
    /*height: 24px;*/
  }
  
  .rheostat-horizontal .rheostat-background {
    height: 5px;
    top: 0px;
    width: 100%;
  }
  
  .rheostat-horizontal .rheostat-progress {
    height: 5px;
    top: 0px;
    position: absolute;
  }
  
  .rheostat-horizontal .rheostat-handle {
    margin-left: -12px;
    top: -10px;
  }
  
  
  .rheostat-vertical {
    height: 100%;
    width: 24px;
  }
  
  .rheostat-vertical .rheostat-background {
    height: 100%;
    top: 0px;
    width: 15px;
  }
  
  .rheostat-vertical .rheostat-progress {
    left: 2px;
    width: 13px;
  }
  
  .rheostat-vertical .rheostat-handle {
    left: -5px;
    margin-top: -12px;
  }
  
  .rheostat-vertical .rheostat-handle:before,
  .rheostat-vertical .rheostat-handle:after {
    left: 8px;
    height: 1px;
    width: 10px;
  }
  
  .rheostat-vertical .rheostat-handle:before {
    top: 10px;
  }
  
  .rheostat-vertical .rheostat-handle:after {
    top: 13px;
  }
  