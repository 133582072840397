.grid-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 310px 310px;
  grid-gap: 8px;
  @apply rounded-t-lg  rounded-b-lg;

  @media (max-width: 768px) {
    grid-template-rows: 180px 180px;
    grid-gap: 6px;
  }
  @media (max-width: 640px) {
    grid-template-rows: 180px 180px;
    grid-gap: 6px;
  }
}

.destination:first-child {
  background-color: #2c2547;
  // border-top-right-radius: 4px;
  // border-bottom-right-radius: 4px;
  // grid-column: span 2;
}

.destination {
  // position: static;
  max-width: 100%;
  flex: 0.2;
  cursor: pointer;
  border-radius: 0px;
}

.destination:nth-child(2),
.destination:nth-child(3),
.destination:nth-child(4),
.destination:nth-child(5) {
  // grid-row: span 2;
}

.inner {
  // border-radius: 4px;
}
.inner {
  position: relative;
  height: 100%;
}
a > * {
  cursor: pointer;
}

.image_container {
  height: 100%;
  max-width: 100%;
}

.text-area {
  position: absolute;
  top: 0px;
  left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 20px;
  @apply p-2 sm:p-4;
}

.destination .pre_title {
  line-height: 20px;
  @apply bg-[rgba(0,0,0,0.4)] p-[2px] px-4 text-white text-vs uppercase font-medium rounded-full;
}

.destination .description {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
}

// .destination button {
//   // background: #ffcd00;
//   // max-width: 150px;

//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px;
//   font-size: 16px;
//   @apply mt-[30px] sm:mt-[60px];
// }

.destination:first-child .dest_image {
  // object-fit: contain;
  // object-position: right;
}
.dest_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @apply transition-all ease-in-out;
}

.dest_image,
.inner {
  // border-radius: 4px;
}

// .destination .image_container:before {
//   content: "";
//   transition: all 0.4s ease;
//   background: rgba(0, 0, 0, 0.3);
//   position: absolute;
//   width: 100%;
//   top: 0;
//   z-index: 1;
//   left: 0;
//   height: 100%;
// }

.destination .title {
  font-style: normal;
}

.destination:hover .image_container:before,
.destination:focus-within .image_container:before,
.destination:focus .image_container:before {
  background: rgba(0, 0, 0, 0.5);
}

.destination:nth-child(6) {
  // grid-column: span 2;
}
.destination {
  overflow: hidden;
}
.destination_info {
  @apply opacity-0 transition-all ease-in-out;
}
.destination:hover,
.destination:focus-within,
.destination:focus {
  .dest_image {
    scale: 1.08;
    @apply transition-all ease-in-out;
  }

  .destination_info {
    opacity: 1;
    @apply transition-all ease-in-out;
  }
}

// .destination button.ui {
//   // background: #ffcd00;
//   // max-width: 150px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px;
//   font-size: 16px;
//   // padding: 8px 24px;
//   // @apply rounded mt-[20px] sm:mt-[70px];

//   .new-arrow-div:hover .new-arrow-div-icon {
//     @apply text-black;
//   }
// }

// object-fit: contain;
// object-position: right;
